<template>
  <div>
    <div class="imgbox color-white" v-if="!item.mode">
      <div style="height: 100vh" class="flex">
        <div
          v-if="item.pages"
          class="pl15 pr15 lh-50"
          style="margin: auto"
          v-html="item.list[active].descs"
        ></div>
        <video
          v-show="item.isvideo && !item.pages"
          loop
          webkit-playsinline
          playsinline
          ref="video1"
          id="video"
          poster
          :muted="true"
          preload="metadata"
          style="clear: both; display: block"
          :style="styleObj"
          :src="item.src"
        ></video>
        <img
          v-show="!item.isvideo"
          :class="item.pages ? 'bgfill' : ''"
          :src="item.src"
        />
      </div>
      <!-- 下面的显示 -->
      <div
        class="pl20 pr20 pb30 pt30 absolute left bottom bgsilces width-full color-white"
      >
        <div class="flex flex-between pt10 pb10">
          <div class="fs22 fw600">{{ item.title1 }}</div>
          <div
            class="b-a-1 fs12 br5 pt5 pb5 text-center w100px"
            @click="runpage(item, 2)"
          >
            订阅更新
          </div>
        </div>

        <!-- icon 循环 -->
        <div
          class="boxtoimg flex flex-center text-center pt20"
          v-if="item.pages"
        >
          <div
            v-for="(atm, dex) in item.list"
            :key="dex"
            class="changheand pl10 pr10"
          >
            <div
              class="flex flex-col align-items-center"
              :class="dex == active ? 'color-white' : 'act'"
              @click="changeacive(dex)"
            >
              <div class="fs32 pt10 pb10" :class="atm.font"></div>
              <div>{{ atm.name }}</div>
            </div>
          </div>
        </div>
        <div v-else class="">
          {{ item.detial }}
        </div>
      </div>
      <!-- 这是头部上面的标题 -->
      <div class="banner-titles absolute axis-x-center text-shadow color-white">
        <div class="fs25 width-full text-center hoverclass letter-5">
          {{ item.title }}
        </div>
        <div class="fs18 width-full pt20 text-center hoverclass letter-5">
          {{ item.desc }}
        </div>
      </div>
    </div>

    <!-- 技术参数 -->
    <div class="bg-black width-full min-vh100 pb50" v-if="item.mode">
      <div class="">
        <div class="pt30 fs30 color-white pl15 pr15 pb50">技术参数</div>
        <div class="pl15 pr15 pt30" v-for="(pop, an) in item.list" :key="an">
          <collitems :title="pop.title" :croped="true">
            <div class="color-white width-full">
              <div class="pt15 pr15" v-for="(con, ind) in pop.descs" :key="ind">
                <div class="width-full flex">
                  <div class="pb5 pt5 pr10 width-auto">{{ con.content }} :</div>
                  <div class="pb5 pt5 flex-1">{{ con.val }}</div>
                </div>
              </div>
            </div>
          </collitems>
        </div>
        <div class="pt30 pl15 fs15 color-white">
          注：产品不断创新，本参数仅供参考，请以实物为准。
        </div>
      </div>
    </div>
  </div>
</template>
        
  <script>
import collitems from "@/components/pc/toopen/collitems";
export default {
  props: ["item"],
  components: {
    collitems,
  },
  data() {
    return {
      videoplayer: null,
      styleObj: {
        // width: "100vw",
        // height: "90vh",
      },
      activeNames: ["1"],
      active: 0,
    };
  },
  mounted() {
    setTimeout(() => {
      this.videoplayer = this.$refs.video1;
    }, 500);
  },
  methods: {
    changeacive(e) {
      this.active = e;
    },
    runpage(path, other) {
      // console.log("跳转页面，", path);
      let name = "";
      if (other == 1) {
        name = "了解更多";
      }
      if (other == 2) {
        name = "订阅更新";
        path.runurl = "/addpreore";
      }
      path.btn_name = name;
      this.$emit("video_runpage", path);
      this.$router.push(path.runurl);
    },
    videoplay() {
      if (this.item.isvideo) {
        this.videoplayer.play();
      }
    },
    videopause() {
      if (this.item.isvideo) {
        this.videoplayer.pause();
      }
    },
  },
};
</script>
        
      <style lang="scss" scoped>
.act {
  color: #c6c6c6;
}
.bgfill {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.banner-titles {
  top: 100px;
  width: 100%;
  // letter-spacing: 5px;
}
.box1200auto {
  width: 1200px;
  margin: 0 auto;
}
.w100px {
  width: 130px;
}
.boxtoimg {
  width: 100%;
  //   height: 100%;
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}

.imgbox {
  width: 100%;
  height: 100vh;
  position: relative;

  .bgsilces {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background: rgba($color: #000000, $alpha: 0.6);
  }
  .videosa {
    bottom: 150px;

    .more {
      background: #e8e9eb;
      //   padding: 10px 50px;
      width: 200px;
      height: 40px;
      line-height: 40px;
      border-radius: 8px;
      margin-right: 100px;
    }
    .more1 {
      background: #e8e9eb;
      width: 200px;
      height: 40px;
      line-height: 40px;
      border-radius: 8px;
    }
  }
  video {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
  .mage1 {
    object-fit: contain;
  }
}
.playnone {
  display: none;
}
</style>