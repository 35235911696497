<template>
  <div>
    <div v-if="!isMobile">
      <div v-for="(item, index) in itemso" :key="index">
        <pcsdetial
          :item="item"
          :ref="'deo' + index"
          @video_runpage="video_runpage"
        ></pcsdetial>
      </div>
    </div>
    <div v-if="isMobile">
      <div v-for="(item, index) in itemso" :key="index">
        <mobilepcsdetial
          :item="item"
          :ref="'deo' + index"
          @video_runpage="video_runpage"
        ></mobilepcsdetial>
      </div>
    </div>
  </div>
</template>

<script>
import imgs1 from "@/assets/images/detileimg/1imgs.jpg";
import imgs3 from "@/assets/images/detileimg/2imgs.jpg";
import imgs2 from "@/assets/images/detileimg/3imgs.jpg";

import p1 from "@/assets/images/pcsbox/P1/p1.jpg";
import p2 from "@/assets/images/pcsbox/P1/p2.jpg";
import p3 from "@/assets/images/pcsbox/P1/p3.jpg";

import p11 from "@/assets/images/pcsbox/P2/p1.png";
import p21 from "@/assets/images/pcsbox/P2/p2.jpg";

import p12 from "@/assets/images/pcsbox/P3/p1.png";

import p41 from "@/assets/images/pcsbox/P4/p1.jpg";
import p42 from "@/assets/images/pcsbox/P4/p2.jpg";

import pcsdetial from "@/components/pc/pcsdetial";
import { mixins } from "@/common/scrollmixin.js";
// 手机
import mobilepcsdetial from "@/components/mobile/mobilepcsdetial";
// 100K 的图
import mp1 from "@/assets/images/pcsbox/P1/mp1.jpg";
import mp2 from "@/assets/images/pcsbox/P1/mp2.jpg";
// 200 k的图
import mp41 from "@/assets/images/pcsbox/P4/mp1.jpg";
import mp42 from "@/assets/images/pcsbox/P4/mp2.jpg";
// 阵列
import mp11 from "@/assets/images/pcsbox/P2/mp1.jpg";
import mp21 from "@/assets/images/pcsbox/P2/mp2.jpg";
// pcs
import mp12 from "@/assets/images/pcsbox/P3/mp1.jpg";

import { mapGetters } from "vuex";

import { look_mixin } from "@/utils/looktime.js";
export default {
  data() {
    return {
      scroll: 0,
      itemso: [],
      detilList: { 1: imgs1, 2: imgs2, 3: imgs3 },
      imgsurl: "",
      videoList: [
        {
          id: 1,
          childs: [
            {
              id: 1,
              title: "Z BOX-C 液冷储能系统",
              title1: "产品介绍",
              desc: "清洁能源创建美好世界",
              src: p1,
              isvideo: false,
              pages: false,
              runurl: "/transtationdetial",
              mode: false,
              detial:
                "Z BOX-C系列液冷储能系统是由卓阳储能自主研发的液冷户外多用途储能解决方案，由电池PACK单体、BMS、EMS、PCS、液冷系统、消防系统等核心部件组成，直流侧电压等级1000V，额定能量186kWh，额定功率93kW，最大可10台并联使用，可应用于用户侧、可再生能源并网、分布式及微网系统，助力零碳目标。",
            },
            {
              id: 2,
              title: "",
              title1: "产品特性",
              desc: "",
              src: p2,
              isvideo: false,
              pages: true,
              runurl: "/transtationdetial",
              mode: false,
              detial:
                "项目建设中，在光伏面板、支架、接线盒等硬件施工和电压输出、远程管理方面取得了多项关键技术突破。",
              list: [
                {
                  id: 1,
                  name: "安全可靠",
                  font: "iconfont icon-dingwei",
                  descs: `<div>
                    -一体化集成设计，安装简单快捷；<br />
                    -两级BMS架构设计，优良的软件鲁棒性；<br />
                    -三级消防架构设计，内嵌10min+提前预警技术；<br />
                    -GB，UL等国内外权威认证标准设计；<br />
                    -高性能、高安全、大容量磷酸铁锂电芯;
                    </div> `,
                },
                {
                  id: 2,
                  name: "经济高效",
                  font: "iconfont icon-qushi",
                  descs: `<div>
                    -液冷技术加持，保证电芯温升3℃以内；<br />
                    -DOD放电深度达到90%以上；<br />
                    -15+年超长设计寿命，系统效率可达93%以上；<br />
                    -All in One一体化集成，可灵活扩展，最大10台；<br />
                    -内置EPS功能，并离网切换时间小于20ms;
                    </div> `,
                },
                {
                  id: 3,
                  name: "智能稳定",
                  font: "iconfont icon-xiaofeng",
                  descs: `<div>
                    -SmartM200支持毫秒级快速响应，实现储能系统；<br />
                    -集中管理，集中调控；<br />
                    -智能BMS系统，主动电芯均衡技术；<br />
                    -整柜IP55等级设计，Pack舱IP67等级设计；<br />
                    -接入卓阳EaaS，远程OTA;
                    </div> `,
                },
                {
                  id: 4,
                  name: "便捷运维",
                  font: "iconfont icon-weixiubeijing",
                  descs: `<div>
                    -Pack模块化设计，即插即用，项目工程产品化；<br />
                    -SmartM200智能检测，智能分析，实现无人值守；<br />
                    -内部分区设计，方便运维和提高系统防护等级；<br />
                    -具备一定移动部署能力；
                    </div> `,
                },
              ],
            },
            {
              id: 3,
              title: "",
              title1: "能源生态",
              desc: "",
              src: p3,
              isvideo: false,
              pages: false,
              runurl: "/transtationdetial",
              mode: false,
              detial:
                "分布式储能产品配合智慧能源系统，为用户提供高效、低成本的电力能源供给模式。结合用户用电习惯及计费模式，电网、光伏、储能相互配合，打造家庭和工商业智慧微电网。",
            },
            {
              id: 4,
              title: "",
              title1: "",
              desc: "",
              src: p3,
              isvideo: false,
              pages: false,
              runurl: "",
              mode: true,
              detial: "",
              list: [
                {
                  id: 1,
                  title: "产品型号",
                  descs: [
                    {
                      content: "产品型号",
                      val: "ZOE-C186L-L-A",
                    },
                  ],
                },
                {
                  id: 2,
                  title: "电池参数",
                  descs: [
                    {
                      content: "电池类型",
                      val: "LFP",
                    },
                    {
                      content: "额定容量",
                      val: "280Ah",
                    },
                    {
                      content: "串并联形式",
                      val: "1P208S",
                    },
                    {
                      content: "单Pack额定能量",
                      val: "46.59kWh",
                    },
                    {
                      content: "系统额定能量",
                      val: "186.3kWh",
                    },
                    {
                      content: "额定电压",
                      val: "665.6Vdc",
                    },
                    {
                      content: "工作电压范围",
                      val: "600~748.8Vdc",
                    },
                  ],
                },
                {
                  id: 3,
                  title: "交流PCS参数",
                  descs: [
                    {
                      content: "额定交流功率",
                      val: "93.1kW",
                    },
                    {
                      content: "最大交流功率",
                      val: "102.4kW（长期）",
                    },
                    {
                      content: "额定电网电压",
                      val: "400Vac",
                    },
                    {
                      content: "电网电压范围",
                      val: "400Vac（-20%~+15%）",
                    },
                    {
                      content: "额定电网频率",
                      val: "50/60±2.5Hz",
                    },
                    {
                      content: "额定输出电流",
                      val: "135A",
                    },
                    {
                      content: "交流接入方式",
                      val: "三相四线",
                    },
                    {
                      content: "电流总谐波畸变率",
                      val: "≤3%（满载）",
                    },
                    {
                      content: "功率因数",
                      val: "-0.99~+0.99",
                    },
                    {
                      content: "充放电转换时间",
                      val: "<100ms",
                    },
                    {
                      content: "并离网切换时间",
                      val: "≤20ms",
                    },
                    {
                      content: "不平衡负载能力",
                      val: "100%",
                    },
                  ],
                },
                {
                  id: 4,
                  title: "常规参数",
                  descs: [
                    {
                      content: "最大效率",
                      val: "≥93%",
                    },
                    {
                      content: "充放电倍率",
                      val: "≤0.5C",
                    },
                    {
                      content: "放电深度",
                      val: "90%DOD",
                    },
                    {
                      content: "防护等级",
                      val: "IP55",
                    },
                    {
                      content: "冷却方式",
                      val: "液冷",
                    },
                    {
                      content: "加热方式",
                      val: "液热",
                    },
                    {
                      content: "消防系统",
                      val: "气溶胶+全氟己酮",
                    },
                    {
                      content: "工作温度",
                      val: "-25~55℃",
                    },
                    {
                      content: "相对湿度",
                      val: "5~95%RH，无凝露",
                    },
                    {
                      content: "噪音",
                      val: "≤65dB",
                    },
                    {
                      content: "最高运行海拔",
                      val: "4000m",
                    },
                    {
                      content: "循环次数",
                      val: "≥8000@25℃，0.5C，70%EOL",
                    },
                    {
                      content: "设计寿命",
                      val: "≥15年",
                    },
                    {
                      content: "通讯接口",
                      val: "RS485/CAN/Ethernet",
                    },
                    {
                      content: "尺寸(W*D*H)",
                      val: "965*1328*1850mm",
                    },
                    {
                      content: "重量",
                      val: "1900kg",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 4,
          childs: [
            {
              id: 1,
              title: "Z BOX-H液冷储能系统",
              title1: "产品介绍",
              desc: "清洁能源创建美好世界",
              src: p41,
              isvideo: false,
              pages: false,
              runurl: "/transtationdetial",
              mode: false,
              detial:
                "CityBox-H系列液冷储能系统是由卓阳储能自主研发的液冷户外多用途储能解决方案，由电池PACK单体、BMS、EMS、液冷系统、消防系统等核心部件组成，直流侧电压等级1500V，额定能量372kWh，最大可扩展至GW/GWh，可应用于用户侧、可再生能源并网、一次调频辅助服务、发输配电、分布式及微网系统，助力零碳目标。",
            },
            {
              id: 2,
              title: "",
              title1: "产品特性",
              desc: "",
              src: p42,
              isvideo: false,
              pages: true,
              runurl: "/transtationdetial",
              mode: false,
              detial:
                "项目建设中，在光伏面板、支架、接线盒等硬件施工和电压输出、远程管理方面取得了多项关键技术突破。",
              list: [
                {
                  id: 1,
                  name: "安全可靠",
                  font: "iconfont icon-dingwei",
                  descs: `<div>
                    -一体化集成设计，安装简单快捷；<br />
                    -两级BMS架构设计，优良的软件鲁棒性；<br />
                    -三级消防架构设计，内嵌10min+提前预警技术；<br />
                    -GB，UL等国内外权威认证标准设计；<br />
                    -高性能、高安全、大容量磷酸铁锂电芯;
                    </div> `,
                },
                {
                  id: 2,
                  name: "经济高效",
                  font: "iconfont icon-qushi",
                  descs: `<div>
                    -液冷技术加持，保证电芯温升3℃以内；<br />
                    -DOD放电深度达到95%以上；<br />
                    -15+年超长设计寿命，系统效率可达95%以上；<br />
                    </div> `,
                },
                {
                  id: 3,
                  name: "智能稳定",
                  font: "iconfont icon-xiaofeng",
                  descs: `<div>
                    -SmartM200支持毫秒级快速响应，实现储能系统；<br />
                    -集中管理，集中调控；<br />
                    -智能BMS系统，主动电芯均衡技术；<br />
                    -整柜IP55等级设计，Pack舱IP67等级设计；<br />
                    -接入卓阳EaaS，远程OTA;
                    </div> `,
                },
                {
                  id: 4,
                  name: "便捷运维",
                  font: "iconfont icon-weixiubeijing",
                  descs: `<div>
                    -Pack模块化设计，即插即用，项目工程产品化；<br />
                    -SmartM200智能检测，智能分析，实现无人值守；<br />
                    -内部分区设计，方便运维和提高系统防护等级；<br />
                    -具备一定移动部署能力；
                    </div> `,
                },
              ],
            },

            {
              id: 3,
              title: "",
              title1: "",
              desc: "",
              src: p3,
              isvideo: false,
              pages: false,
              runurl: "",
              mode: true,
              detial: "",
              list: [
                {
                  id: 1,
                  title: "产品型号",
                  descs: [
                    {
                      content: "产品型号",
                      val: "ZOE-C372L-H-A",
                    },
                  ],
                },
                {
                  id: 2,
                  title: "电池参数",
                  descs: [
                    {
                      content: "电池类型",
                      val: "LFP",
                    },
                    {
                      content: "额定容量",
                      val: "280Ah",
                    },
                    {
                      content: "串并联形式",
                      val: "1P416S",
                    },
                    {
                      content: "单Pack额定能量",
                      val: "46.59kWh",
                    },
                    {
                      content: "系统额定能量",
                      val: "372.7kWh",
                    },
                    {
                      content: "额定电压",
                      val: "1331.2Vdc",
                    },
                    {
                      content: "工作电压范围",
                      val: "1164.8~1497.6Vdc",
                    },
                  ],
                },
                {
                  id: 3,
                  title: "常规参数",
                  descs: [
                    {
                      content: "最大效率",
                      val: "≥95%",
                    },
                    {
                      content: "充放电倍率",
                      val: "≤0.5C",
                    },
                    {
                      content: "放电深度",
                      val: "95%DOD",
                    },
                    {
                      content: "防护等级",
                      val: "IP55",
                    },
                    {
                      content: "冷却方式",
                      val: "液冷",
                    },
                    {
                      content: "加热方式",
                      val: "液热",
                    },
                    {
                      content: "消防系统",
                      val: "气溶胶+全氟己酮",
                    },
                    {
                      content: "工作温度",
                      val: "-25~55℃",
                    },
                    {
                      content: "相对湿度",
                      val: "5~95%RH，无凝露",
                    },
                    {
                      content: "噪音",
                      val: "≤65dB",
                    },
                    {
                      content: "最高运行海拔",
                      val: "4000m",
                    },
                    {
                      content: "循环次数",
                      val: "≥8000@25℃，0.5C，70%EOL",
                    },
                    {
                      content: "设计寿命",
                      val: "≥15年",
                    },
                    {
                      content: "通讯接口",
                      val: "RS485/CAN/Ethernet",
                    },
                    {
                      content: "尺寸(W*D*H)",
                      val: "1370*1330*2270mm",
                    },
                    {
                      content: "重量",
                      val: "3550kg",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 2,
          childs: [
            {
              id: 1,
              title: "CityBox阵列",
              title1: "产品介绍",
              desc: "清洁能源创造美好世界",
              src: p11,
              isvideo: false,
              pages: false,
              runurl: "/transtationdetial",
              mode: false,
              detial:
                "CityBox-H阵列系列储能液冷子阵是由卓阳储能自主研发的液冷户外多用途储能解决方案，由16台CityBox-H液冷户外柜组成，直流侧电压等级1500V，额定能量5963kWh，额定功率2981kW，主要应用于可再生能源并网、发输配电等大型集中式或者独立储能项目。",
            },
            {
              id: 2,
              title: "",
              title1: "产品特性",
              desc: "",
              src: p21,
              isvideo: false,
              pages: true,
              runurl: "/transtationdetial",
              mode: false,
              detial:
                "项目建设中，在光伏面板、支架、接线盒等硬件施工和电压输出、远程管理方面取得了多项关键技术突破；",
              list: [
                {
                  id: 1,
                  name: "安全可靠",
                  font: "iconfont icon-dingwei",
                  descs: `<div>
                    -一体化集成设计，安装简单快捷；<br />
                    -两级BMS架构设计，优良的软件鲁棒性；<br />
                    -三级消防架构设计，内嵌10min+提前预警技术；<br />
                    -GB，UL等国内外权威认证标准设计；<br />
                    -高性能、高安全、大容量磷酸铁锂电芯;
                    </div> `,
                },
                {
                  id: 2,
                  name: "经济高效",
                  font: "iconfont icon-qushi",
                  descs: `<div>
                    -液冷技术加持，保证电芯温升3℃以内；<br />
                    -DOD放电深度达到95%以上；<br />
                    -15+年超长设计寿命，系统效率可达95%以上；<br />
                    </div> `,
                },
                {
                  id: 3,
                  name: "智能稳定",
                  font: "iconfont icon-xiaofeng",
                  descs: `<div>
                    -SmartM200支持毫秒级快速响应，实现储能系统；<br />
                    -集中管理，集中调控；<br />
                    -智能BMS系统，主动电芯均衡技术；<br />
                    -整柜IP55等级设计，Pack舱IP67等级设计；<br />
                    -接入卓阳EaaS，远程OTA;
                    </div> `,
                },
                {
                  id: 4,
                  name: "便捷运维",
                  font: "iconfont icon-weixiubeijing",
                  descs: `<div>
                    -Pack模块化设计，即插即用，项目工程产品化；<br />
                    -SmartM200智能检测，智能分析，实现无人值守；<br />
                    -内部分区设计，方便运维和提高系统防护等级；<br />
                    -具备一定移动部署能力；
                    </div> `,
                },
              ],
            },
            {
              id: 3,
              title: "",
              title1: "",
              desc: "",
              src: "",
              isvideo: false,
              pages: false,
              runurl: "",
              mode: true,
              detial: "",
              list: [
                {
                  id: 1,
                  title: "产品型号",
                  descs: [
                    {
                      content: "产品型号",
                      val: "ZOE-A6000L-H-A",
                    },
                  ],
                },
                {
                  id: 2,
                  title: "电池参数",
                  descs: [
                    {
                      content: "电池类型",
                      val: "LFP",
                    },
                    {
                      content: "额定容量",
                      val: "4480Ah",
                    },
                    {
                      content: "串并联形式",
                      val: "16P416S",
                    },
                    {
                      content: "系统额定能量",
                      val: "5963kWh",
                    },
                    {
                      content: "额定电压",
                      val: "1331.2V",
                    },
                    {
                      content: "工作电压范围",
                      val: "1164.8~1497.6V",
                    },
                  ],
                },
                {
                  id: 3,
                  title: "交流PCS参数",
                  descs: [
                    {
                      content: "额定交流功率",
                      val: "2981kW",
                    },
                    {
                      content: "额定电网电压",
                      val: "690V",
                    },
                    {
                      content: "额定电网频率",
                      val: "50/60Hz",
                    },
                    {
                      content: "交流接入方式",
                      val: "三相三线",
                    },
                    {
                      content: "总谐波畸变率",
                      val: "<2%",
                    },
                    {
                      content: "功率因数",
                      val: "-1~+1",
                    },
                    {
                      content: "最大转换效率",
                      val: "≥99%",
                    },
                    {
                      content: "功率响应速度:",
                      val: "<30ms",
                    },
                    {
                      content: "充放电转换时间:",
                      val: "<50ms",
                    },
                  ],
                },
                {
                  id: 4,
                  title: "常规参数",
                  descs: [
                    {
                      content: "最大效率",
                      val: "≥95%",
                    },
                    {
                      content: "充放电倍率",
                      val: "≤0.5C",
                    },
                    {
                      content: "放电深度",
                      val: "95%DOD",
                    },
                    {
                      content: "防护等级",
                      val: "IP55",
                    },
                    {
                      content: "冷却方式",
                      val: "液冷",
                    },
                    {
                      content: "加热方式",
                      val: "液热",
                    },
                    {
                      content: "消防系统",
                      val: "气溶胶+全氟己酮",
                    },
                    {
                      content: "工作温度",
                      val: "-25~55℃",
                    },
                    {
                      content: "相对湿度",
                      val: "5~95%RH，无凝露",
                    },
                    {
                      content: "噪音",
                      val: "≤65dB",
                    },
                    {
                      content: "最高运行海拔",
                      val: "4000m",
                    },
                    {
                      content: "循环次数",
                      val: "≥8000@25℃，0.5C，70%EOL",
                    },
                    {
                      content: "设计寿命",
                      val: "≥15年",
                    },
                    {
                      content: "通讯接口",
                      val: "RS485/CAN/Ethernet",
                    },
                    {
                      content: "尺寸(W*D*H)",
                      val: "14520*2960*2270mm",
                    },
                    {
                      content: "重量",
                      val: "59T",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 3,
          childs: [
            {
              id: 1,
              title: "ZOE-PW100-L-A",
              title1: "产品特性",
              desc: "双向组串式储能变流器",
              src: p12,
              isvideo: false,
              pages: false,
              runurl: "/transtationdetial",
              mode: false,
              detial:
                "PCS智能组串式储能变流器是一款双向组串式储能变流器，具有易于安装、多台扩展、效率高、安全性高等优点，可与储能系统共同组成超大型能源阵列，可应用于发电侧、电网侧、用户侧，助力国家双碳目标的达成。",
            },
            {
              id: 2,
              title: "",
              title1: "",
              desc: "",
              src: "",
              isvideo: false,
              pages: false,
              runurl: "",
              mode: true,
              detial: "",
              list: [
                {
                  id: 1,
                  title: "直流侧参数",
                  descs: [
                    {
                      content: "最大直流电压",
                      val: "1000Vdc",
                    },
                    {
                      content: "直流工作电压范围",
                      val: "600~1000Vdc",
                    },
                    {
                      content: "最大直流电流",
                      val: "183A",
                    },
                    {
                      content: "直流满载电压范围",
                      val: "600~850Vdc",
                    },
                  ],
                },
                {
                  id: 2,
                  title: "交流侧参数",
                  descs: [
                    {
                      content: "额定输出功率",
                      val: "100kW",
                    },
                    {
                      content: "最大输出功率",
                      val: "110kVA（持续）",
                    },
                    {
                      content: "额定电网电压",
                      val: "400Vac",
                    },
                    {
                      content: "额定电网频率",
                      val: "50Hz/60Hz",
                    },
                    {
                      content: "最大输出电流",
                      val: "158A",
                    },
                    {
                      content: "功率因数",
                      val: "-0.99~+0.99",
                    },
                    {
                      content: "THDi",
                      val: "<3%（额定输出功率）",
                    },
                    {
                      content: "充放电转换时间",
                      val: "<100ms",
                    },
                    {
                      content: "并离网切换时间",
                      val: "≤20ms",
                    },
                    {
                      content: "不平衡负载能力",
                      val: "100%",
                    },
                  ],
                },

                {
                  id: 3,
                  title: "常规参数",
                  descs: [
                    {
                      content: "最大效率",
                      val: "≥99%",
                    },
                    {
                      content: "隔离变压器",
                      val: "无",
                    },
                    {
                      content: "防护等级",
                      val: "IP65",
                    },
                    {
                      content: "工作温度",
                      val: "-30~60℃（>40℃降额）",
                    },
                    {
                      content: "相对湿度",
                      val: "0~100%",
                    },
                    {
                      content: "冷却方式",
                      val: "强制风冷",
                    },
                    {
                      content: "最高运行海拔",
                      val: "4000m（>3000m降额）",
                    },
                    {
                      content: "通信协议",
                      val: "Modbus-RTU/Modbus-TCP/IEC61850/CAN",
                    },
                    {
                      content: "尺寸(W*D*H)",
                      val: "600*480*221mm",
                    },
                    {
                      content: "重量",
                      val: "60kg",
                    },
                    {
                      content: "标准",
                      val: "GB/T 34120-2017,GB/T 34133-2017,IEEE 1547,IEC 62477-1,IEC 61000-6-2,IEC 61000-6-4",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    };
  },
  components: {
    pcsdetial,
    mobilepcsdetial,
  },
  computed: {
    ...mapGetters(["isMobile"]),
  },
  mixins: [mixins, look_mixin],
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    console.log(this.$route.params.id);
    this.imgsurl = this.detilList[this.$route.params.id];
    this.itemso = this.videoList[this.$route.params.id - 1].childs;

    if (this.isMobile) {
      this.videoList[0].childs[0].src = mp1;
      this.videoList[0].childs[1].src = mp2;
      this.videoList[1].childs[0].src = mp41;
      this.videoList[1].childs[1].src = mp42;
      this.videoList[2].childs[0].src = mp11;
      this.videoList[2].childs[1].src = mp21;
      this.videoList[3].childs[0].src = mp12;
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    video_runpage(e) {
      this.user_btn_click(e.title1, e.runurl, e.btn_name);
    },
    handleScroll() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 第一个屏幕
      if (this.scroll < this.positionList[1].top + 25) {
        this.$refs.deo0[0].videoplay();
        this.$refs.deo1[0].videopause();
        this.$refs.deo2[0].videopause();
      }
      // 第二个屏幕
      if (
        this.scroll < this.positionList[2].top - 150 &&
        this.scroll > this.positionList[1].top - 150
      ) {
        this.$refs.deo0[0].videopause();
        this.$refs.deo1[0].videoplay();
        this.$refs.deo2[0].videopause();
      }
      // 第三个屏幕
      if (this.scroll > this.positionList[2].top - 150) {
        this.$refs.deo0[0].videopause();
        this.$refs.deo1[0].videopause();
        this.$refs.deo2[0].videoplay();
      }
      // 第四个屏幕
      // if (this.scroll > this.positionList[3].top - 150) {

      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.imgbox {
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}
</style>