<template>
  <div>
    <div class="imgbox bg-black color-white" v-if="!item.mode">
      <div style="height: 80vh" class="flex">
        <div
          v-if="item.pages"
          style="width: 430px; padding-top: 18%"
          class="pl30 pr30 lh-50"
          v-html="item.list[active].descs"
        ></div>
        <video
          v-show="item.isvideo"
          loop
          ref="video1"
          id="video"
          poster
          :muted="true"
          preload="metadata"
          style="clear: both; display: block"
          :style="styleObj"
          :src="item.src"
          :class="item.pages ? 'flex-1' : ''"
        ></video>
        <img
          v-show="!item.isvideo"
          :src="item.src"
          :class="item.pages ? 'flex-1' : ''"
          alt=""
        />
      </div>
      <!-- 下面的显示 -->
      <div class="flex box1200auto align-items-center" style="height: 200px">
        <div>
          <div class="fs22 w100px fw600">{{ item.title1 }}</div>
          <div
            class="b-a-1 mt20 fs12 br5 pt5 pb5 text-center"
            @click="runpage(item, 2)"
          >
            订阅更新
          </div>
        </div>

        <!-- icon 循环 -->
        <div
          class="boxtoimg flex flex-center pl50 align-items-center"
          v-if="item.pages"
        >
          <div
            v-for="(atm, dex) in item.list"
            :key="dex"
            class="ml50 mr50 changheand"
          >
            <div
              class="flex flex-col align-items-center"
              :class="dex == active ? 'color-white' : 'act'"
              @click="changeacive(dex)"
            >
              <div class="fs45 pb10" :class="atm.font"></div>
              <div>{{ atm.name }}</div>
            </div>
          </div>
        </div>
        <div v-else class="pl50">
          {{ item.detial }}
        </div>
      </div>
      <!-- 这是头部上面的标题 -->
      <div class="banner-titles absolute axis-x-center text-shadow color-white">
        <div class="fs36 width-full text-center hoverclass letter-5">
          {{ item.title }}
        </div>
        <div class="fs18 width-full pt20 text-center hoverclass letter-5">
          {{ item.desc }}
        </div>
      </div>
    </div>

    <!-- 技术参数 -->
    <div class="bg-black width-full min-vh100 pb50" v-if="item.mode">
      <div class="box1200auto">
        <div class="pt30 fs30 color-white pb50">技术参数</div>
        <div class="pl50 pt30" v-for="(pop, an) in item.list" :key="an">
          <collitems :title="pop.title" :croped="true">
            <div class="color-white width-full flex flex-betwween flex-wrap">
              <div
                class="width-50 pt30"
                v-for="(con, ind) in pop.descs"
                :key="ind"
              >
                <div class="flex">
                  <div class="width-30">{{ con.content }}</div>
                  <div class="">{{ con.val }}</div>
                </div>
              </div>
            </div>
          </collitems>
        </div>
        <div class="pt30 fs15 color-white">
          注：产品不断创新，本参数仅供参考，请以实物为准。
        </div>
      </div>
    </div>
  </div>
</template>
      
<script>
import collitems from "@/components/pc/toopen/collitems";
export default {
  props: ["item"],
  components: {
    collitems,
  },
  data() {
    return {
      videoplayer: null,
      styleObj: {
        // width: "100vw",
        // height: "90vh",
      },
      activeNames: ["1"],
      active: 0,
    };
  },
  mounted() {
    setTimeout(() => {
      this.videoplayer = this.$refs.video1;
    }, 500);
  },
  methods: {
    changeacive(e) {
      this.active = e;
    },
    runpage(path, other) {
      // console.log("跳转页面，", path);
      let name = "";
      if (other == 1) {
        name = "了解更多";
      }
      if (other == 2) {
        name = "订阅更新";
        path.runurl = "/addpreore";
      }
      path.btn_name = name;
      this.$emit("video_runpage", path);
      this.$router.push(path.runurl);
    },
    videoplay() {
      if (this.item.isvideo) {
        this.videoplayer.play();
      }
    },
    videopause() {
      if (this.item.isvideo) {
        this.videoplayer.pause();
      }
    },
  },
};
</script>
      
    <style lang="scss" scoped>
.act {
  color: #c6c6c6;
}
.banner-titles {
  top: 100px;
  width: 100%;
  // letter-spacing: 5px;
}
.box1200auto {
  width: 1200px;
  margin: 0 auto;
}
.w100px {
  width: 130px;
}
.boxtoimg {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}

.imgbox {
  width: 100%;
  height: 100vh;
  position: relative;
  .videosa {
    bottom: 150px;

    .more {
      background: #e8e9eb;
      //   padding: 10px 50px;
      width: 200px;
      height: 40px;
      line-height: 40px;
      border-radius: 8px;
      margin-right: 100px;
    }
    .more1 {
      background: #e8e9eb;
      width: 200px;
      height: 40px;
      line-height: 40px;
      border-radius: 8px;
    }
  }
  video {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
  .mage1 {
    object-fit: contain;
  }
}
.playnone {
  display: none;
}
</style>